import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PeAdminRoutingModule } from "./pe-admin-routing.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { GlobalModule } from "../global/global.module";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatChipsModule } from "@angular/material/chips";
import { OfferMarkupModule } from "./offer-markup/offer-markup.module";
import { LeaseSettingModule } from "./lease-setting/lease-setting.module";
import { ConfigNotFoundComponent } from "./config-not-found/config-not-found.component";
import { AssetUrlPipe } from "../../../app/pipes/assets";
import { TranslateModule } from "@ngx-translate/core";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ConfigNotFoundComponent],
  imports: [
    CommonModule,
    PeAdminRoutingModule,
    TranslateModule.forChild({ extend: true }),
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode'}),
    DragDropModule,
    GlobalModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    OfferMarkupModule,
    MatChipsModule,
    LeaseSettingModule,
  ],
  exports: [MatIconModule, MatDialogModule],
  providers: [AssetUrlPipe],
})
export class PeAdminModule {}
