<dd-spinner *ngIf="(loadingGP$ | async) || (selectBankConfigLoading$ | async)"></dd-spinner>
<div class="bodyDiv container-fluid grossProfitConfig" *ngIf="grossProfitConfig">
    <mat-label class="bodyDivHeader">{{'LOCALIZATION.GROSS_PROFIT.HEADER_NOTE' | translate}}</mat-label>
    <div class="row">
        <div class="col-md-3">
            <mat-label class="fw-bold">
                Select participation plan for Finance
            </mat-label>
            <mat-select panelClass="pe-admin-select" [disabled]="(disableActions$ | async)" [(ngModel)]="gPformatted.participationPlan.finance">
                <mat-option *ngFor="let ppItem of grossProfitConfig.participationPlans.finance" [value]="ppItem.id">
                    {{ppItem.displayName}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-3">
            <mat-label class="fw-bold">
                Select participation plan for Lease
            </mat-label>
            <mat-select panelClass="pe-admin-select" [disabled]="(disableActions$ | async)" [(ngModel)]="gPformatted.participationPlan.lease">
                <mat-option *ngFor="let ppItem of grossProfitConfig.participationPlans.lease" [value]="ppItem.id">
                    {{ppItem.displayName}}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <pre> <mat-label class="subHeader">{{'LOCALIZATION.GROSS_PROFIT.SUB_HEADER_NOTE' | translate}}</mat-label></pre>
    <div class="row">
        <div class="col-md-6 front-end-container">
            <table class="table example-full-width mt-3" aria-describedby="tableHd">
                <thead>
                    <th scope="col" class="pl-2">
                        {{'LOCALIZATION.GROSS_PROFIT.FRONT_END_VALUE_HEADER' | translate}}</th>
                    <th scope="col" class="pl-2">{{'LOCALIZATION.GROSS_PROFIT.INCLUDE_HEADER' |
                        translate}}
                    </th>

                </thead>
                <tbody>
                    <ng-container *ngIf="(gPformatted | keyvalue)?.length">
                        <tr [ngClass]="{'border-bottom': !lastRow}"
                            *ngFor="let itemsFrontEnd of gPformatted.frontEnd; let i = index; last as lastRow; first as firstRow">
                            <td class="text-cap">
                                {{i+1}}. {{itemsFrontEnd.name}}
                                <em *ngIf="itemsFrontEnd.description" class="gp-factor tmi tmi-info p-0"
                                    matTooltip="{{itemsFrontEnd.description}}"></em>
                            </td>
                            <td class="d-flex">
                                <mat-slide-toggle [(ngModel)]="itemsFrontEnd.include" [class.disabled]="(itemsFrontEnd.id | disabledealercash) || (disableActions$ | async)"
                                    [disabled]="(itemsFrontEnd.id | disabledealercash) || (disableActions$ | async)">
                                </mat-slide-toggle>
                            </td>

                        </tr>
                    </ng-container>
                    <ng-container *ngIf="!(gPformatted | keyvalue)?.length">
                        <tr>
                            <td class="d-flex">
                                {{'LOCALIZATION.GROSS_PROFIT.NO_RECORDS' | translate}}
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="col-md-6 back-end-container">
            <table class="table example-full-width mt-3" aria-describedby="tableHd">
                <thead>
                    <th scope="col" class="pl-2">
                        {{'LOCALIZATION.GROSS_PROFIT.BACK_END_VALUE_HEADER' | translate}}</th>
                    <th scope="col" class="pl-2">{{'LOCALIZATION.GROSS_PROFIT.INCLUDE_HEADER' |
                        translate}}
                    </th>

                </thead>
                <tbody>
                    <ng-container *ngIf="(gPformatted | keyvalue)?.length">
                        <tr [ngClass]="{'border-bottom': !lastRow}"
                            *ngFor="let itemsBackEnd of gPformatted.backEnd; let i = index; last as lastRow; first as firstRow">
                            <td class="text-cap">
                                {{i+1}}. {{itemsBackEnd.name}}
                                <em *ngIf="itemsBackEnd.description" class="gp-factor tmi tmi-info p-0"
                                    matTooltip="{{itemsBackEnd.description}}"></em>
                            </td>
                            <td class="d-flex">
                                <mat-slide-toggle [disabled]="(disableActions$ | async)" [class.disabled]="(disableActions$ | async)" [(ngModel)]="itemsBackEnd.include">
                                </mat-slide-toggle>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="!(gPformatted | keyvalue)?.length">
                        <tr>
                            <td class="d-flex">
                                {{'LOCALIZATION.GROSS_PROFIT.NO_RECORDS' | translate}}
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="d-flex mb-2">
        <input *ngIf="!(disableActions$ | async)" type="button" class="secondary-button mt-3"
            [value]="translateService.instant('LOCALIZATION.COMMON.RESET')" (click)="resetConfig()">
        <input *ngIf="!(disableActions$ | async)" type="button" class="primary-button mt-3 ml-3"
            [value]="translateService.instant('LOCALIZATION.COMMON.SAVE')" (click)="saveConfig()" />
    </div>
</div>