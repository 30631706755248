import { IProductConfiguration } from './../../shared/models/protection-products.model';
import { IPennProvider, IPennProduct } from './../../shared/models/product-offerings.model';
import { IDealerAddOnValidation, IFieldConfig, IFieldItem, ISaveProducts, IGetPennProductDefaults, IPennProductDefaults } from "../../shared/models/protection-products.model";
import * as _ from 'lodash';
import { Draft  } from 'immer';
import { ProtectionProductsState } from './protection-products.reducer';
import { SavePpApiInstruction } from '../../shared/enums/pp-save-api-instruction.enum';

function getProductTypeForProduct(providers: IPennProvider[], providerName: string, productName: string): string {
    const products: IPennProduct[] = _.find(providers, { name: providerName })?.products || [];
    return _.find(products, { name: productName })?.productType || '';
}

function getRequiredFields(dealerAddOnValidations: IDealerAddOnValidation[], productTypesId: string): string[] {
    const productDynamicFieldData: IDealerAddOnValidation = _.find(dealerAddOnValidations, { optionsValue: productTypesId });
    return productDynamicFieldData?.requiredFields || [];
}

export function getDynamicFieldsForProduct(fieldData: IFieldConfig, providers: IPennProvider[], providerName: string, productName: string): IFieldItem[] {
    if (providerName && fieldData && fieldData?.fields && fieldData?.dealerAddOnValidations && providers) {
        const productTypesId = getProductTypeForProduct(providers, providerName, productName);
        const requiredFields = getRequiredFields(fieldData.dealerAddOnValidations, productTypesId);
        const productDynamicFieldData: IFieldItem[] = _.filter(fieldData.fields, (t: IFieldItem) => _.includes(requiredFields, t.fieldId));
        return _.sortBy(productDynamicFieldData, (fieldDetail) => {
            return _.indexOf(requiredFields, fieldDetail.fieldId);
        });
    }
    return [];
}

export function updateTfsProductsSuccess(
    state: ProtectionProductsState,
    draftState: Draft <ProtectionProductsState>,
    updateProductDetails: ISaveProducts
) {
    if (updateProductDetails) {
        updateProductDetails.configurationData.forEach((productConfig, index) => {
            if (productConfig.instruction.name === SavePpApiInstruction.UPDATE) {
                const protectionProducts = _.cloneDeep(state.protectionProducts);
                const productIndex = _.findIndex(protectionProducts.products, (t) => t.id === productConfig.id);
                protectionProducts.products[productIndex] = productConfig;
                draftState.protectionProducts.products = protectionProducts.products;
            }
            else if (productConfig.instruction.name === SavePpApiInstruction.DELETE) {
                draftState.productOfferings = _.filter(state.productOfferings, (t) => t.id != productConfig.id);
                draftState.protectionProducts.products = _.filter(state.protectionProducts.products, (t) => t.id != productConfig.id);
            }
            else if (productConfig.instruction.name === SavePpApiInstruction.CREATE) {
                const productMasterData = _.cloneDeep(_.find(updateProductDetails.masterData, (t => t.id === productConfig.id)));

                if (productMasterData.code) {
                    delete productMasterData.code;
                }
                const productConfigData = _.cloneDeep(productConfig);
                if (productConfigData.instruction) {
                    delete productConfigData.instruction;
                }
                
                // deleting with literal notation since this property is not part of IProductConfiguration
                if (productConfigData['providerId']) {
                    delete productConfigData['providerId'];
                }
                draftState.productOfferings = [...state.productOfferings, productMasterData];
                draftState.protectionProducts.products = [...state.protectionProducts.products, productConfigData];
            }
        });
    }
}

export function updateTfsProductsOrderSuccess(
    state: ProtectionProductsState,
    draftState: Draft <ProtectionProductsState>,
    updateProductDetails: ISaveProducts
) {
    if (updateProductDetails?.preferenceOrder) {
        const productsData: IProductConfiguration[] = [];
        _.forEach(state.protectionProducts.products, (product, index, allProducts) => {
            const order = _.find(updateProductDetails.preferenceOrder, { objectId: product.id })?.preferenceOrder;
            const productConfig = _.cloneDeep(product);
            productConfig.global.order = typeof (order) === 'number' ? order : allProducts.length;
            productsData.push(productConfig);
        });
        draftState.protectionProducts.products = productsData;
    }
}

export function updatePennProductDefaultsList(
    state: ProtectionProductsState,
    pennProducts: IGetPennProductDefaults
): IPennProductDefaults[] {
    const pennProductList = [...state.pennProductDefaultsList];
    _.forEach(pennProducts.configurationData, (configurationData) => {
        let pennProduct = _.find(pennProductList, (t => t.providerName === configurationData.global.provider && t.productName === configurationData.id));
        if (!pennProduct) {
            const masterData = _.find(pennProducts.masterData, (t => t.code === configurationData.id));
            pennProduct = {
                providerName: configurationData.global.provider,
                productName: configurationData.id,
                masterData,
                configurationData
            };
            pennProductList.push(pennProduct);
        }
    });
    return pennProductList;
}