import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { IncentiveStatus } from '../../../shared/enums/incentive-status.enum';
import { IIncentive } from '../../../shared/models/incentives.model';
import { SubscriptionList, unsubscribeSubscriptions } from '../../../shared/services/util.service';
import { DealerConfigurationState } from '../../../store/dealer-configuration/dealer-configuration.reducer';
import { IncentivesState } from '../../../store/incentives/incentives.reducer';
import { IncentivesComponentService } from '../incentives.component.service';
import * as incentivesActions from '../../../store/incentives/incentives.actions';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import { ModifyIncentiveComponent } from '../modify-incentive/modify-incentive.component';
import { GenericActions } from '../../../shared/enums/operation-actions.enum';

@Component({
  selector: 'app-pe-view-incentive',
  templateUrl: './view-incentive.component.html',
  styleUrls: ['./view-incentive.component.scss']
})
export class ViewIncentiveComponent implements OnInit {

  incentiveStatus = IncentiveStatus;
  incentiveStatusToggle: FormControl;
  private readonly subs: SubscriptionList = {};
  disableActions$: Observable<boolean>;

  @Input() incentive: IIncentive;

  constructor(
    private readonly incentivesState: Store<IncentivesState>,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly incentiveService: IncentivesComponentService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.disableActions$ = of(false);// this.dealerConfigurationState.select(selectDisableActions);
    this.subs.selectDisableActionsSub = this.disableActions$.subscribe(disableActions => {
      if (disableActions) {
        this.incentiveStatusToggle.disable({ emitEvent: false });
      }
    });
    this.setIncentiveStatusChange();
  }

  editIncentive() {
    this.dialog.open(ModifyIncentiveComponent, {
      panelClass: ['modify-incentive-panel', 'pe-admin-dialog'],
      minWidth: '64%',
      disableClose: true,
      data: {
        action: GenericActions.UPDATE,
        incentive: this.incentive
      }
    });
  }

  cloneIncentive() {
    this.dialog.open(ModifyIncentiveComponent, {
      panelClass: ['modify-incentive-panel', 'pe-admin-dialog'],
      minWidth: '64%',
      disableClose: true,
      data: {
        action: GenericActions.CLONE,
        incentive: {
          ..._.cloneDeep(this.incentive), id: uuid.v4(), title: '',
          startDate: null, endDate: null, status: this.incentiveStatus.DRAFT
        }
      }
    });
  }

  deleteIncentiveConfirmed() {
    this.incentivesState.dispatch(
      new incentivesActions.DeleteIncentive({ incentiveId: this.incentive.id, dealerCode: this.incentive.dealerCode })
    );
  }

  /* istanbul ignore next */
  deleteIncentive() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      panelClass: ['pe-admin-dialog'],
      data: {
        popupBodyText: this.translateService.instant(
          'LOCALIZATION.INCENTIVES.DELETE_CONFIRM_TEXT'
        ),
        confirmButtonText: this.translateService.instant(
          'LOCALIZATION.COMMON.DELETE'
        ),
      },
    });
    const afterClosedSub = dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === 'confirm') {
        this.deleteIncentiveConfirmed();
      }
      afterClosedSub.unsubscribe();
    });
  }

  /* istanbul ignore next */
  setIncentiveStatusChange() {
    this.incentiveStatusToggle = new FormControl(this.incentive.status === this.incentiveStatus.ACTIVE);
    this.subs.incentiveStatusToggleChange = this.incentiveStatusToggle.valueChanges.subscribe(active => {
      const incentive = _.cloneDeep(this.incentive);
      if (active) {
        const startDate = this.incentiveService.getLuxonUTCDate(this.incentive.startDate);
        const endDate = this.incentiveService.getLuxonUTCDate(this.incentive.endDate);
        if (
          this.incentive.status !== this.incentiveStatus.DRAFT ||
          (this.incentiveService.currentAndFutureDateCheck(startDate) &&
            this.incentiveService.currentAndFutureDateCheck(endDate) &&
            !this.incentiveService.checkInvalidStartAndEndDate(startDate, endDate))
        ) {
          const conflictingIncentives = []; // this.incentiveService.findConflictingIncentive(this.incentive);
          if (conflictingIncentives.length > 0) {
            this.snackBar.open(
              this.translateService.instant(
                'LOCALIZATION.INCENTIVES.DUPLICATE_INCENTIVE_ALERT',
                { incentives: conflictingIncentives.reduce((prev, current, i) => prev.concat(`${i + 1}. ${current}\n`), '') }
              ),
              this.translateService.instant('LOCALIZATION.COMMON.DISMISS'),
              { duration: 4000, panelClass: ['translated-newline'] }
            );
            setTimeout(() => {
              this.incentiveStatusToggle.patchValue(false, { emitEvent: false });
            }, 0);
            return;
          }
          incentive.status = this.incentiveStatus.ACTIVE;
        }
        else {
          const errorType = 'UNABLE_TO_ACTIVATE_PREDATED';
          this.snackBar.open(
            this.translateService.instant(`LOCALIZATION.INCENTIVES.${errorType}`),
            this.translateService.instant('LOCALIZATION.COMMON.DISMISS'),
            { duration: 4000 }
          );
          setTimeout(() => {
            this.incentiveStatusToggle.patchValue(false, { emitEvent: false });
          }, 0);
          return;
        }
      } else {
        incentive.status = this.incentiveStatus.INACTIVE;
      }
      this.updateIncentiveStatus(active, incentive);
    });
  }

  updateIncentiveConfirmed(incentive: IIncentive) {
    this.incentivesState.dispatch(
      new incentivesActions.UpdateIncentive({ incentive })
    );
  }

  /* istanbul ignore next */
  updateIncentiveStatus(active: boolean, incentive: IIncentive) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      panelClass: ['pe-admin-dialog'],
      data: {
        popupBodyText: this.translateService.instant(
          `LOCALIZATION.INCENTIVES.${active ? 'ACTIVATE_CONFIRM' : 'DEACTIVATE_CONFIRM'}`),
        confirmButtonText: this.translateService.instant('LOCALIZATION.COMMON.YES'),
      },
    });
    const afterClosedSub = dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === 'confirm') {
        this.updateIncentiveConfirmed(incentive);
      }
      else {
        this.incentiveStatusToggle.patchValue(!active, { emitEvent: false });
      }
      afterClosedSub.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

}
