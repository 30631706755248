<div [formGroup]="dealValidationForm">
    <div formGroupName="quote" class="bodyDiv container-fluid">
        <mat-label class="bodyDivHeader">{{'LOCALIZATION.DEAL_VALIDATION.VEHICLE_DETAILS' | translate}}</mat-label>
        <div class="row no-gutters p-0">
            <div class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">{{'LOCALIZATION.DEAL_VALIDATION.VIN' | translate}}</mat-label>
                <input formControlName="vin"
                    [ngClass]="{'input-error': dealValidationForm['controls'].quote['controls'].vin.invalid }"
                    class="deal-input" type="text" maxlength="17" />
            </div>
            <div class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">{{'LOCALIZATION.DEAL_VALIDATION.SELLINGPRICE' | translate}}</mat-label>
                <div class="pos-relative">
                    <span class="dollar-symbol">$&nbsp;</span>
                    <input formControlName="sellingPrice"
                        [ngClass]="{'input-error': dealValidationForm['controls'].quote['controls'].sellingPrice.invalid }"
                        class="deal-input dollar-input no-number-arrow" min="0" type="number">
                </div>
            </div>
            <div class="d-flex flex-column ml-3 mt-2 justify-content-end">
                <input type="button" class="primary-button search-btn" [disabled]="quoteForm.invalid"
                    (click)="loadDealerOffers()" value="{{'LOCALIZATION.DEAL_VALIDATION.SEARCH' | translate}}" />
            </div>
        </div>
    </div>
    <div formGroupName="offer" class="bodyDiv container-fluid mt-2">
        <mat-label class="bodyDivHeader">{{'LOCALIZATION.DEAL_VALIDATION.OFFER DETAILS' | translate}}</mat-label>
        <div class="row no-gutters p-0">
            <div class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">{{'LOCALIZATION.DEAL_VALIDATION.ZIPCODE' | translate}}</mat-label>
                <input formControlName="zipCode"
                    [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].zipCode.invalid }"
                    class="deal-input no-number-arrow" type="text" />
            </div>
            <div class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">{{'LOCALIZATION.DEAL_VALIDATION.DEALTYPE' | translate}}</mat-label>
                <mat-select panelClass="pe-admin-select" formControlName="dealType"
                    [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].dealType.invalid }"
                    class="deal-input">
                    <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                    <mat-option *ngFor="let offer of (dealTypes$ | async) || []" [value]="offer">{{('LOCALIZATION.DEAL_VALIDATION.'+offer) | translate}}
                    </mat-option>
                </mat-select>
            </div>
            <div *ngIf="dealValidationForm['controls'].offer['controls'].level.enabled" class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">{{'LOCALIZATION.DEAL_VALIDATION.CREDITTIER' | translate}}</mat-label>
                <mat-select panelClass="pe-admin-select" formControlName="level"
                    [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].level.invalid }"
                    class="deal-input">
                    <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                    <mat-option *ngFor="let level of (dealTiers$ | async) || []" [value]="level">{{level}}
                    </mat-option>
                </mat-select>
            </div>
            <div *ngIf="dealValidationForm['controls'].offer['controls'].term.enabled" class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">{{'LOCALIZATION.DEAL_VALIDATION.TERMLENGTH' | translate}}</mat-label>
                <mat-select panelClass="pe-admin-select" formControlName="term"
                    [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].term.invalid }"
                    class="deal-input">
                    <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                    <mat-option *ngFor="let term of (dealTerms$ | async) || []" [value]="term">{{term}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">{{'LOCALIZATION.DEAL_VALIDATION.FUNDINGSOURCE' | translate}}</mat-label>
                <mat-select panelClass="pe-admin-select" formControlName="fundingSource"
                    [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].fundingSource.invalid }"
                    class="deal-input">
                    <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                    <mat-option *ngIf="dealerInfoService.isTFSDealer()" value="TFS">{{'LOCALIZATION.DEAL_VALIDATION.TFS' | translate}}</mat-option>
                    <mat-option *ngIf="dealerInfoService.isSETDealer()" value="SETF">{{'LOCALIZATION.DEAL_VALIDATION.SETF' | translate}}</mat-option>
                    <mat-option value="3rd Party Lendor">{{'LOCALIZATION.DEAL_VALIDATION.3RDPARTY' | translate}}</mat-option>
                </mat-select>
            </div>
            <div *ngIf="dealValidationForm['controls'].offer['controls'].rate.enabled" class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">
                    {{ dealValidationForm['controls'].offer['controls'].dealType.value | ratetitle }}
                </mat-label>
                <mat-select panelClass="pe-admin-select" formControlName="rate"
                    [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].rate.invalid }"
                    class="deal-input">
                    <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                    <mat-option *ngFor="let dealRate of (dealRates$ | async) || []" [value]="dealRate">
                        {{dealRate.rate}}&nbsp;{{(dealRate.isSubvented ? ('LOCALIZATION.DEAL_VALIDATION.special' | translate) : ('LOCALIZATION.DEAL_VALIDATION.standard' | translate))}}
                    </mat-option>
                </mat-select>
            </div>
            <div *ngIf="dealValidationForm['controls'].offer['controls'].mileage.enabled"
                class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">{{'LOCALIZATION.DEAL_VALIDATION.ANNUALMILEAGE' | translate}}</mat-label>
                <mat-select panelClass="pe-admin-select" formControlName="mileage"
                    [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].mileage.invalid }"
                    class="deal-input">
                    <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                    <mat-option value="10000">10000</mat-option>
                    <mat-option value="20000">20000</mat-option>
                    <mat-option value="30000">30000</mat-option>
                    <mat-option value="40000">40000</mat-option>
                    <mat-option value="50000">50000</mat-option>
                </mat-select>
            </div>
        </div>
        <div class="row no-gutters p-0">
            <div class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">{{'LOCALIZATION.DEAL_VALIDATION.CASHDOWN' | translate}}</mat-label>
                <div class="pos-relative">
                    <span class="dollar-symbol">$&nbsp;</span>
                    <input formControlName="downPayment"
                        [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].downPayment.invalid }"
                        class="deal-input dollar-input no-number-arrow" min="0" type="number">
                </div>
            </div>
            <div class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold">{{'LOCALIZATION.DEAL_VALIDATION.NETTRADE' | translate}}</mat-label>
                <div class="pos-relative">
                    <span class="dollar-symbol">$&nbsp;</span>
                    <input formControlName="tradeIn"
                        [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].tradeIn.invalid }"
                        class="deal-input dollar-input no-number-arrow" min="0" type="number">
                </div>
            </div>
            <div class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold">{{'LOCALIZATION.DEAL_VALIDATION.INCENTIVES' | translate}}</mat-label>
                <mat-select panelClass="pe-admin-select" formControlName="incentive"
                    [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].incentive.invalid }"
                    class="deal-input">
                    <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                    <mat-option value="military">{{'LOCALIZATION.DEAL_VALIDATION.MILITARY' | translate}}</mat-option>
                    <mat-option value="collegegraduate">{{'LOCALIZATION.DEAL_VALIDATION.COLLEGEGRADUATE' | translate}}</mat-option>
                </mat-select>
            </div>
            <div class="d-flex flex-column mr-2 mt-2">
                <mat-label class="mb-2 fw-bold required-val">{{'LOCALIZATION.DEAL_VALIDATION.TAXESANDFEES' | translate}}</mat-label>
                <mat-select panelClass="pe-admin-select" formControlName="disableQuoteWithoutTaxes"
                    [ngClass]="{'input-error': dealValidationForm['controls'].offer['controls'].disableQuoteWithoutTaxes.invalid }"
                    class="deal-input">
                    <mat-option [value]="false">{{'LOCALIZATION.COMMON.YES' | translate}}</mat-option>
                    <mat-option [value]="true">{{'LOCALIZATION.COMMON.NO' | translate}}</mat-option>
                </mat-select>
            </div>
            <div class="d-flex flex-column ml-3 mt-2 justify-content-end">
                <input type="button" class="primary-button search-button"
                    [disabled]="!(isDealOffersLoaded$ | async) || offerForm.invalid || offerForm.disabled"
                    (click)="calculatePayment()" value="{{'LOCALIZATION.DEAL_VALIDATION.CALCULATEPAYMENT' | translate}}" />
            </div>
        </div>
    </div>
</div>