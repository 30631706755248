import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { selectActiveCampaign, selectCampaignLenders, selectCampaignLoading, selectDealerDefinedCampaigns, selectExpiredCampaign } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.selectors';
import { DealerDefinedCampaignsState } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.reducer';
import { selectLenderList } from '../../../store/dealer-configuration/dealer-configuration.selectors';
import { DealerConfigurationState } from '../../../store/dealer-configuration/dealer-configuration.reducer';
import { ILenderInfo } from '../../../shared/models/dealer-configuration.model';
import { IDealerDefinedCampaign } from '../../../shared/models/dealer-defined-campaigns.model';
import { ModifyCampaignComponent } from '../modify-campaign/modify-campaign.component';
import { DealerDefinedCampaignsComponentService } from '../dealer-defined-campaigns.component.service';
import { GenericActions } from '../../../shared/enums/operation-actions.enum';
import { finalize, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionList, unsubscribeSubscriptions } from '../../../shared/services/util.service';
@Component({
  selector: 'app-pe-list-campaign',
  templateUrl: './list-campaign.component.html',
  styleUrls: ['./list-campaign.component.scss']
})
export class ListCampaignComponent implements OnInit {

  selectCampaignLoading$: Observable<boolean>;
  lenderList$: Observable<string[]>;
  activeLenderList$: Observable<ILenderInfo[]>;
  campaigns$: Observable<IDealerDefinedCampaign[]>;
  disableActions$: Observable<boolean>;
  isActive = true;
  private subs: SubscriptionList = {};

  constructor(
    private readonly dealerDefinedCampaignsState: Store<DealerDefinedCampaignsState>,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly dDCCService: DealerDefinedCampaignsComponentService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.selectCampaignLoading$ = this.dealerDefinedCampaignsState.select(selectCampaignLoading);
    this.lenderList$ = this.dealerDefinedCampaignsState.select(selectCampaignLenders);
    this.activeLenderList$ = this.dealerConfigurationState.select(selectLenderList);
    this.campaigns$ = this.dealerDefinedCampaignsState.select(selectActiveCampaign);
    this.disableActions$ = of(false); // this.dealerConfigurationState.select(selectDisableActions);
  }

  addCampaign() {
    this.dialog.open(ModifyCampaignComponent, {
      panelClass: ['modify-campaign-panel', 'pe-admin-dialog'],
      minWidth: '64%',
      disableClose: true,
      data: {
        action: GenericActions.ADD,
        campaign: this.dDCCService.buildDraftCampaign()
      }
    });
  }

  toggleActive() {
    this.isActive = !this.isActive;
    if (this.isActive) {
      this.campaigns$ = this.dealerDefinedCampaignsState.select(selectActiveCampaign);
    } else {
      this.campaigns$ = this.dealerDefinedCampaignsState.select(selectExpiredCampaign);
    }
    this.checkEmptyData();
  }

  showNotification() {
    this.isActive ?
      this.snackBar.open(
        this.translateService.instant('LOCALIZATION.CAMPAIGNS.ACTIVE_NOT_FOUND',),
        this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 }
      ) :
      this.snackBar.open(
        this.translateService.instant('LOCALIZATION.CAMPAIGNS.EXPIRED_NOT_FOUND'),
        this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 }
      );
  }

  checkEmptyData() {
    this.subs.getIncentivesLength = this.campaigns$.pipe(
      take(1),
      finalize(() => { })
    ).subscribe((item: IDealerDefinedCampaign[]) => {
      if (item.length <= 0) {
        this.showNotification();
      }
    });
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

}
