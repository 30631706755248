import { IBuilderRequest, IBuilderResponse } from './../../shared/models/pe-builder.model';
import {IVehicleDetailsDD365Response } from './../../shared/models/pe-dd365-vehicledetails.model';
import { DealerService } from './../../../service/dealers/dealer.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect , ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as fromActions from './deal-validation.actions';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ICalculatorResponse } from '../../shared/models/pe-calculator.model';


@Injectable()
export class DealValidationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly dealerService: DealerService,
        private readonly snackBar: MatSnackBar
    ) {
    }

    LoadVehicleDetailsFromDD365: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType<fromActions.LoadVehicleDetailsFromDD365>(
                fromActions.DealValidationActionTypes.LOAD_VEHDETAILS_FROM_DD365
            ),
            mergeMap((action: fromActions.LoadVehicleDetailsFromDD365) =>
            
                this.dealerService.getVehicleDetailsFromDD365(action.payload.vehicleRequest).pipe(
                    map((vehicleDetails: IVehicleDetailsDD365Response) => new fromActions.LoadVehicleDetailsFromDD365Success({ vehicleDetails })),
                    catchError(err => of(new fromActions.LoadVehicleDetailsFromDD365Failure({ error: err })))
                )
            )    
        )
    );

    loadOffersFromBuilder: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType<fromActions.LoadOffersFromBuilder>(
                fromActions.DealValidationActionTypes.LOAD_OFFERS_FROM_BUILDER
            ),
            mergeMap((action: fromActions.LoadOffersFromBuilder) =>
                this.dealerService.getQuoteFromBuilder(action.payload.quoteRequest).pipe(
                    map((quoteDetails: IBuilderResponse) => new fromActions.LoadOffersFromBuilderSuccess({ quoteDetails })),
                    catchError(err => of(new fromActions.LoadOffersFromBuilderFailure({ error: err })))
                )
            )
        )
    );

    loadDealFromCalculator: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType<fromActions.LoadDeal>(
                fromActions.DealValidationActionTypes.LOAD_DEAL
            ),
            mergeMap((action: fromActions.LoadDeal) =>
                this.dealerService.getDealFromCalculator(action.payload.offerType, action.payload.dealRequest).pipe(
                    map((dealDetails: ICalculatorResponse) => new fromActions.LoadDealSuccess({ dealDetails })),
                    catchError(err => of(new fromActions.LoadDealFailure({ error: err })))
                )
            )
        )
    );

}

