import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OfferMarkupRoutingModule } from './offer-markup-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { GlobalModule } from '../../global/global.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DirectivesModule } from '../../../models/directives/directives.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OfferMarkupComponent } from './offer-markup.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    OfferMarkupComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({ extend: true }),
    OfferMarkupRoutingModule,
    MatFormFieldModule,
    FormsModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatIconModule,
    MatRadioModule,
    ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode'}),
    GlobalModule,
    MatSnackBarModule,
    DirectivesModule,
    MatTooltipModule,
    MatSidenavModule,
    SharedModule
  ],
  providers: []
})
export class OfferMarkupModule { }
