import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { GenericActions } from '../../../shared/enums/operation-actions.enum';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { CampaignStatus } from '../../../shared/enums/campaign-status.enum';
import { OfferTypes } from '../../../shared/enums/offer-types.enum';
import { ICreditTier } from '../../../shared/models/bank-configuration.model';
import { IDealerDefinedCampaign, IRateRule } from '../../../shared/models/dealer-defined-campaigns.model';
import { DealerConfigurationState } from '../../../store/dealer-configuration/dealer-configuration.reducer';
import { selectCreditTier, selectDisableActions } from '../../../store/dealer-configuration/dealer-configuration.selectors';
import * as dealerDefinedCampaignsActions from "../../../store/dealer-defined-campaigns/dealer-defined-campaigns.actions";
import { DealerDefinedCampaignsState } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.reducer';
import { ModifyRateRuleComponent } from '../modify-rate-rule/modify-rate-rule.component';

@Component({
  selector: 'app-pe-view-rate-rule',
  templateUrl: './view-rate-rule.component.html',
  styleUrls: ['./view-rate-rule.component.scss']
})
export class ViewRateRuleComponent implements OnInit {

  campaignStatus = CampaignStatus;
  leaseOfferType: string = OfferTypes.LEASE;
  isAdvPercentageBased = false;
  creditTiers$: Observable<ICreditTier[]>;
  disableActions$: Observable<boolean>;

  @Input() campaign: IDealerDefinedCampaign;
  @Input() rateRule: IRateRule;
  @Input() ruleIndex: number;

  constructor(
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly dealerDefinedCampaignsState: Store<DealerDefinedCampaignsState>,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService
  ) { }

  ngOnInit() {
    this.disableActions$ = of(false); // this.dealerConfigurationState.select(selectDisableActions);
    this.creditTiers$ = this.dealerConfigurationState.select(selectCreditTier);
    this.isAdvPercentageBased = this.getIsAdvPercentageBased();
  }

  editRateRule() {
    this.dialog.open(ModifyRateRuleComponent, {
      panelClass: ['modify-rate-rule-panel', 'pe-admin-dialog'],
      disableClose: true,
      data: {
        action: GenericActions.UPDATE,
        campaign: this.campaign,
        rateRule: this.rateRule,
        ruleIndex: this.ruleIndex
      }
    });
  }

  deleteRateRuleConfirmed() {
    const campaign = _.cloneDeep(this.campaign);
    campaign.rateRules.splice(this.ruleIndex, 1);
    this.filterResiduals(campaign);
    this.dealerDefinedCampaignsState.dispatch(
      new dealerDefinedCampaignsActions.UpdateDealerDefinedCampaign({ campaign })
    );
  }

  /* istanbul ignore next */
  deleteRateRule() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "400px",
      panelClass: ['pe-admin-dialog'],
      data: {
        popupBodyText: this.translateService.instant(
          "LOCALIZATION.CAMPAIGNS.DELETE_RULE_CONFIRM_TEXT"
        ),
        confirmButtonText: this.translateService.instant(
          "LOCALIZATION.COMMON.DELETE"
        ),
      },
    });
    const afterClosedSub = dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === "confirm") {
        this.deleteRateRuleConfirmed();
      }
      afterClosedSub.unsubscribe();
    });
  }

  getIsAdvPercentageBased() {
    return !(_.some(this.rateRule.termRate,
      (t) =>
        _.some(t.advancePercentage, { min: null, max: null }))
    );
  }

  filterResiduals(campaign: IDealerDefinedCampaign) {
    campaign.residuals = campaign.residuals.filter(t =>
      !(_.indexOf(this.rateRule.modelCode, t.modelCode) >= 0 &&
        _.indexOf(this.rateRule.salesClass, t.salesClass) >= 0 &&
        _.indexOf(this.campaign.year, t.modelYear) >= 0)
    );
  }

}
