import * as fromActions from './vehicle-config.actions';
import { produce } from "immer";
import { IVehicleBrand } from '../../shared/models/vehicle-config-master.model';
import { getInitialVehicleConfig, getYearList, mapNHTSAVehicleConfig, mapRTIVehicleConfig, removeBrandFromLoadingList } from './vehicle-config.adapter';
import * as _ from 'lodash';

export interface VehicleConfigState {
  vehicleConfig: IVehicleBrand[],
  brandsLoading: string[],
  yearMasterList: number[],
  configLoadCounter: number,
  loading: boolean,
  error: string
}

const initialState: VehicleConfigState = {
  vehicleConfig: getInitialVehicleConfig(),
  brandsLoading: [],
  yearMasterList: getYearList(),
  configLoadCounter: 0,
  loading: false,
  error: ''
};

export function vehicleConfigReducer(
  state: VehicleConfigState = initialState,
  action: fromActions.VehicleConfigActions
): VehicleConfigState {
  switch (action.type) {
    case fromActions.VehicleConfigActionTypes.LOAD_RTI_VEHICLE_CONFIG:
    case fromActions.VehicleConfigActionTypes.LOAD_NHTSA_VEHICLE_CONFIG: {
      return produce(state, draftState => {
        draftState.loading = true;
        draftState.error = '';
      });
    }
    case fromActions.VehicleConfigActionTypes.LOAD_RTI_VEHICLE_CONFIG_SUCCESS: {
      return produce(state, draftState => {
        const vehicleConfig = action instanceof fromActions.LoadRTIVehicleConfigSuccess ?
          mapRTIVehicleConfig(action.payload.brand, action.payload.rtiVehicleConfig) : undefined;
        if (vehicleConfig) {
          draftState.vehicleConfig.push(vehicleConfig);
        }
        draftState.configLoadCounter++;
        removeBrandFromLoadingList(
          action instanceof fromActions.LoadRTIVehicleConfigSuccess ? action.payload.brand : '',
          draftState.brandsLoading);
        draftState.loading = false;
        draftState.error = '';
      });
    }
    case fromActions.VehicleConfigActionTypes.LOAD_RTI_VEHICLE_CONFIG_FAILURE: {
      return produce(state, draftState => {
        removeBrandFromLoadingList(
          action instanceof fromActions.LoadRTIVehicleConfigFailure ? action.payload.brand : '',
          draftState.brandsLoading);
        draftState.loading = false;
        draftState.error = action instanceof fromActions.LoadRTIVehicleConfigFailure ? action.payload.error : '';
      });
    }
    case fromActions.VehicleConfigActionTypes.LOAD_NHTSA_VEHICLE_CONFIG_SUCCESS: {
      return produce(state, draftState => {
        const vehicleConfig = action instanceof fromActions.LoadNHTSAVehicleConfigSuccess ?
          mapNHTSAVehicleConfig(action.payload.brand, action.payload.nhtsaVehicleConfig) : undefined;
        if (vehicleConfig) {
          draftState.vehicleConfig.push(vehicleConfig);
        }
        draftState.configLoadCounter++;
        removeBrandFromLoadingList(
          action instanceof fromActions.LoadNHTSAVehicleConfigSuccess ? action.payload.brand : '',
          draftState.brandsLoading);
        draftState.loading = false;
        draftState.error = '';
      });
    }
    case fromActions.VehicleConfigActionTypes.LOAD_NHTSA_VEHICLE_CONFIG_FAILURE: {
      return produce(state, draftState => {
        removeBrandFromLoadingList(
          action instanceof fromActions.LoadNHTSAVehicleConfigFailure ? action.payload.brand : '',
          draftState.brandsLoading);
        draftState.loading = false;
        draftState.error = action instanceof fromActions.LoadNHTSAVehicleConfigFailure ? action.payload.error : '';
      });
    }
    case fromActions.VehicleConfigActionTypes.UPDATE_BRANDS_LOADING: {
      return produce(state, draftState => {
        const brand = action instanceof fromActions.UpdateBrandsLoading ? action.payload.brand : '';
        draftState.brandsLoading = _.union(draftState.brandsLoading, [brand]);
      });
    }
    default:
      return state;
  }
}
