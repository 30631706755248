<div class="vehicle-pac-container">
    <dd-spinner *ngIf="(selectDealerConfigLoading$ | async) || !(selectSystemConfigLoading$ | async)">
    </dd-spinner>
    <div *ngIf="!(selectDealerConfigLoading$ | async) && (selectSystemConfigLoading$ | async)">
        <ng-container *ngIf="vehiclePacComponentService.vehiclePacForm">
            <div [formGroup]="vehiclePacComponentService.vehiclePacForm"
                class="offset-lg-2 col-lg-8 mt-3">
                <mat-label class="d-flex mb-3">{{'LOCALIZATION.VEHICLE_PAC.HEADER_NOTE' |
                    translate}}</mat-label>
                <div *ngFor="let salesClass of vehiclePacComponentService.configurableSalesClasses, last as lastRow"
                    class="bodyDiv container-fluid" [ngClass]="{'mb-0': lastRow}" [formGroupName]="salesClass">
                    <mat-label class="bodyDivHeader">{{salesClass | titlecase}}</mat-label>
                    <div class="d-flex mb-3">
                        <mat-label class="my-2 fw-bold required-val w-375-px">
                            {{'LOCALIZATION.VEHICLE_PAC.PAC_AMOUNT' | translate}}
                        </mat-label>
                        <div class="pos-relative my-1 ml-4">
                            <span class="dollar-symbol">$&nbsp;</span>
                            <input formControlName="pacCost"
                                [matTooltip]="getTooltip(vehiclePacComponentService.vehiclePacForm.get(salesClass).get('pacCost'))"
                                [ngClass]="{'input-error': vehiclePacComponentService.vehiclePacForm.get(salesClass).get('pacCost').invalid }"
                                class="pac-input dollar-input no-number-arrow" type="number">
                        </div>
                    </div>
                    <div class="d-flex">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title class="fw-bold">
                                    <div>{{'LOCALIZATION.VEHICLE_PAC.PAC_BY_SERIES' | translate:({"pac":
                                        getAdjustmentLength(salesClass)})}} </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <table aria-describedby="tableHd" class="table">
                                <thead>
                                    <tr>
                                        <th>{{'LOCALIZATION.VEHICLE_PAC.MAKE' | translate}}</th>
                                        <th>{{'LOCALIZATION.VEHICLE_PAC.MODEL' | translate}}</th>
                                        <th>{{'LOCALIZATION.VEHICLE_PAC.ADJUSTMENT' | translate}}</th>
                                        <th>{{'LOCALIZATION.VEHICLE_PAC.ADJUSTED_PAC' | translate}}</th>
                                        <th>
                                            <input type="button" *ngIf="!vehiclePacComponentService.isDisableFormActions"
                                                class="primary-button small-button ml-3"
                                                (click)="addPacSeries(salesClass)"
                                                value="{{'LOCALIZATION.COMMON.ADD' | translate}}" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="adjustments"
                                    *ngIf="getAdjControls(salesClass) && getAdjControls(salesClass).length > 0">
                                    <tr *ngFor="let adjustment of getAdjControls(salesClass).controls; let i = index"
                                        [formGroupName]="i">
                                        <td>
                                            <mat-select class="select d-flex my-auto make" formControlName="make"
                                                (selectionChange)="changeMake(salesClass,adjustment,i)"
                                                [ngClass]="isInvalid(adjustment, 'make')"
                                                [matTooltip]="getTooltip(adjustment.get('make'))">
                                                <mat-option value="toyota">{{'LOCALIZATION.BRANDS.TOYOTA'
                                                    |translate}}</mat-option>
                                                <mat-option value="lexus">{{'LOCALIZATION.BRANDS.LEXUS'
                                                    |translate}}</mat-option>
                                                <mat-option *ngIf="salesClass == 'used'"
                                                    value="allntl">{{'LOCALIZATION.BRANDS.ALL_NTL' | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </td>
                                        <td>
                                            <mat-select formControlName="series"
                                                [matTooltip]="getTooltip(adjustment.get('series'))"
                                                matTooltipShowDelay="500" [ngClass]="isInvalid(adjustment, 'series')"
                                                class="select series" multiple selectAll>
                                                <mat-option [value]="allOptionValue">{{'LOCALIZATION.COMMON.ALL' |
                                                    translate}}</mat-option>
                                                <mat-option *ngFor="let model of adjustment.get('make')?.value
                                                    | getbrandseries : (vehicleConfig$ | async)" [value]="model.code">
                                                    {{model.name}}
                                                </mat-option>
                                            </mat-select>
                                        </td>
                                        <td>
                                            <input type="number" matTooltipShowDelay="500"
                                                [ngClass]="isInvalid(adjustment, 'adjustment')" [pattern]="decimalRegex" autocomplete="off"
                                                class="input d-flex my-auto no-number-arrow" formControlName="adjustment"
                                                [matTooltip]="getTooltip(adjustment.get('adjustment'))"
                                                (keyup)="changeAdjustment(adjustment, salesClass)"/>
                                        </td>
                                        <td>
                                            <input type="number" matTooltipShowDelay="500"
                                                [ngClass]="isInvalid(adjustment, 'adjustedPac')"
                                                [pattern]="decimalRegex" autocomplete="off"
                                                class="input d-flex my-auto no-number-arrow"
                                                (keyup)="changeAdjustedPac(adjustment, salesClass)"
                                                formControlName="adjustedPac"
                                                [matTooltip]="getTooltip(adjustment.get('adjustedPac'))" />
                                        </td>
                                        <td>
                                            <mat-icon *ngIf="!vehiclePacComponentService.isDisableFormActions" class="pointer highlight_off"
                                                (click)="deletePacSeries(salesClass,i)">close
                                            </mat-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-expansion-panel>
                    </div>
                </div>
                <div class="d-flex mb-2" *ngIf="!vehiclePacComponentService.isDisableFormActions">
                    <input type="button" class="secondary-button mt-3" (click)="resetVehiclePac()"
                        [value]="translateService.instant('LOCALIZATION.COMMON.RESET')">
                    <input type="button" class="primary-button mt-3 ml-3"
                        [disabled]="vehiclePacComponentService.vehiclePacForm == 'INVALID'" (click)="saveVehiclePac()"
                        [value]="translateService.instant('LOCALIZATION.COMMON.SAVE')" />
                </div>
            </div>
        </ng-container>
    </div>
</div>