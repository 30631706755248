<div *ngIf="(incentiveService.vehicleConfigLoading$ | async) || (incentiveService.incentiveLoading$ | async)">
  <dd-spinner>
  </dd-spinner>
</div>
<div class="close-dialog-icon-container d-flex">
  <mat-icon class="close-dialog-icon" mat-dialog-close>close</mat-icon>
</div>
<div class="common-incentive-container">
  <div class="modify-incentive-container">
    <div class="header">
      <div mat-dialog-title>
        {{('LOCALIZATION.INCENTIVES.'+ data.action + '_INCENTIVE') | translate}}
      </div>
    </div>
  </div>
  <div [formGroup]="incentiveForm" class="modify-incentive-container container-fluid p-3">
    <div class="d-flex">
      <div class="col-md-3">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.INCENTIVE_NAME' | translate}}
        </mat-label>
        <input [ngClass]="{'input-error': incentiveForm.controls.title.invalid}" formControlName="title"
          class="incentive-input" type="text" maxlength="50" />
      </div>
      <div class="col-md-3">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.MAKE' | translate}}
        </mat-label>
        <mat-select panelClass="pe-admin-select" [ngClass]="{'input-error': incentiveForm.controls.make.invalid}" formControlName="make"
          class="incentive-input" (selectionChange)="onMakeChange($event.value,makeChange)">
          <mat-option *ngFor="let make of (brandList$ | async) | filterbrandlist" [value]="make.id">
            {{make.name}}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-md-3">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.MODEL' | translate}}
        </mat-label>
        <mat-select panelClass="pe-admin-select" [ngClass]="{'input-error': incentiveForm.controls.model.invalid}" formControlName="model"
          class="incentive-input" multiple selectAll>
          <mat-option [value]="allOptionValue">{{'LOCALIZATION.COMMON.ALL' | translate}}</mat-option>
          <mat-option *ngFor="let series of incentiveForm.controls.make.value
                      | getbrandseries : (vehicleConfig$ | async)" [value]="series.code">
            {{series.name}}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-md-3">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.YEAR' | translate}}
        </mat-label>
        <mat-select panelClass="pe-admin-select" [ngClass]="{'input-error': incentiveForm.controls.year.invalid}" formControlName="year"
          class="incentive-input" multiple selectAll>
          <mat-option [value]="allOptionValue">{{'LOCALIZATION.COMMON.ALL' | translate}}</mat-option>
          <mat-option *ngFor="let year of (yearMasterList$ | async)" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="d-flex mt-2">
      <div class="col-md-3">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.SALES_CLASS' | translate}}
        </mat-label>
        <mat-select panelClass="pe-admin-select" [ngClass]="{'input-error': incentiveForm.controls.salesClass.invalid }" formControlName="salesClass"
          class="incentive-input" multiple>
          <mat-option [value]="salesClass" *ngFor="let salesClass of incentiveForm.value | getsalesclassesforincentive">
            {{salesClass | titlecase}}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-md-3">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.OFFER_TYPE' | translate}}
        </mat-label>
        <mat-select panelClass="pe-admin-select" [ngClass]="{'input-error': incentiveForm.controls.offerType.invalid }" formControlName="offerType"
          class="incentive-input" multiple>
          <mat-option *ngFor="let offerType of offerTypeList" [value]="offerType">
            {{offerType | titlecase}}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-md-3">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.SOURCE' | translate}}
        </mat-label>
        <input [ngClass]="{'input-error': incentiveForm.controls.incentiveSource.invalid}"
          formControlName="incentiveSource" class="incentive-input" type="text" maxlength="50" />
      </div>
      <div class="col-md-3">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.INCENTIVE_AMOUNT' | translate}}
        </mat-label>
        <input [ngClass]="{'input-error': incentiveForm.controls.incentiveAmount.invalid}"
          formControlName="incentiveAmount" class="incentive-input" type="number" step="any" min="0" maxlength="5" />
      </div>
    </div>
    <div class="d-flex mt-2">
      <div class="col-md-3 pe-toggle">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.DEALER_FUNDED' | translate}}
        </mat-label>
        <mat-slide-toggle formControlName="dealerFunded">
        </mat-slide-toggle>
      </div>
      <div class="col-md-3">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.START_DATE' | translate}}
        </mat-label>
        <input [ngClass]="{'input-error': incentiveForm.controls.startDate.invalid ||
          incentiveForm.errors?.InvalidDates}" formControlName="startDate" matInput autocomplete="off"
          class="incentive-input" placeholder="mm/dd/yyyy" [matDatepicker]="startDate"
          [matDatepickerFilter]="incentiveService.currentAndFutureDateCheck">
        <mat-datepicker-toggle disableRipple matSuffix [for]="startDate">
          <mat-icon matDatepickerToggleIcon>
            <em class="tmi tmi-date"></em>
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </div>
      <div class="col-md-3">
        <mat-label class="d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.EXPIRATION_DATE' | translate}}
        </mat-label>
        <input [ngClass]="{'input-error': incentiveForm.controls.endDate.invalid ||
          incentiveForm.errors?.InvalidDates}" formControlName="endDate" matInput autocomplete="off"
          class="incentive-input" placeholder="mm/dd/yyyy" [matDatepicker]="endDate"
          [matDatepickerFilter]="incentiveService.currentAndFutureDateCheck">
        <mat-datepicker-toggle disableRipple matSuffix [for]="endDate">
          <mat-icon matDatepickerToggleIcon>
            <em class="tmi tmi-date"></em>
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </div>
    </div>

    <div class="d-flex mt-1">
      <div class="col-md-12">
        <mat-label class=" d-flex fw-bold">
          {{'LOCALIZATION.INCENTIVES.DISCLOSURE' | translate}}
        </mat-label>
        <p class="disclaimer-for-disclosure mb-1">Please use the field below to provide consumers with all the
          information they need about the incentive, including but not limited to a description of the terms for the
          incentive and any qualifications or restrictions that may apply. Dealers are responsible for ensuring that the
          information included in this field complies with all applicable laws and is accurate and complete.</p>
        <textarea [ngClass]="{'input-error': incentiveForm.controls.disclosure.invalid}" formControlName="disclosure"
          class="incentive-description form-outline" rows="5" placeholder="Enter Disclosure" maxlength="2000">
         </textarea>
      </div>
    </div>
    <div class="d-flex mt-2">
      <div class="col-md-12">
        <mat-label class=" d-flex mb-1 fw-bold required-val">
          {{'LOCALIZATION.INCENTIVES.DESCRIPTION' | translate}}
        </mat-label>
        <textarea [ngClass]="{'input-error': incentiveForm.controls.description.invalid}" formControlName="description"
          class="incentive-description form-outline" rows="5" placeholder="Enter Description" maxlength="2000">
              </textarea>
      </div>
    </div>
  </div>
</div>

<div class=" d-flex mt-2 mr-3">

  <button class="secondary-button ml-auto mr-3" #matDialogCloseBtn mat-dialog-close>
    {{'LOCALIZATION.COMMON.CANCEL' | translate}}
  </button>
  <button type="button" class="primary-button" [disabled]="incentiveForm.invalid" (click)="saveIncentive()">
    {{'LOCALIZATION.COMMON.' + data.action | translate}}
  </button>
</div>