import { IPennProvider } from './../../shared/models/product-offerings.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProtectionProductsService } from './../../shared/services/protection-products.service';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as fromActions from './protection-products.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { IGetProducts, IGetPennProductDefaults, ISaveProducts, ITaxCategory, IPacChangelog } from '../../shared/models/protection-products.model';
import { ProtectionProductsComponentService } from '../../pe-admin/protection-product/protection-products.component.service';
import { ProtectionProductsState } from './protection-products.reducer';
import { DealerConfigVersion } from '../../shared/enums/dc-version.enum';
import { UserProfileService } from "@toyota/dd365-platform-library";
import { DeployJobManageState } from "../../store/deploy-job-management/deploy-job-management.reducer";
import * as deployJobManagementActions from '../../store/deploy-job-management/deploy-job-management.actions';

@Injectable()
export class ProtectionProductsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly protectionProductsService: ProtectionProductsService,
        private readonly pPComponentService: ProtectionProductsComponentService,
        private readonly protectionProductsState: Store<ProtectionProductsState>,
        private readonly snackBar: MatSnackBar,
        private readonly userProfileService: UserProfileService,
        private readonly deployJobManageState: Store<DeployJobManageState>,
    ) {
    }

    loadProductOfferings: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
        ofType<fromActions.LoadProductOfferings>(
            fromActions.ProtectionProductsActionTypes.LOAD_PRODUCT_OFFERINGS
        ),
        mergeMap((action: fromActions.LoadProductOfferings) =>
            this.protectionProductsService.getProductOfferings(
                action.payload.dealerCode, action.payload.draftStatus ? DealerConfigVersion.DRAFT : DealerConfigVersion.ACTIVE
            ).pipe(
                map((productDetails: IGetProducts) => new fromActions.LoadProductOfferingsSuccess({ productDetails })),
                catchError(err => {
                    if (err.status === 404) {
                        return of(new fromActions.ProductOfferingsNotFound({ error: err.error?.message, dynamicFields: err.error?.dynamicFields }));
                    }
                    else {
                        return of(new fromActions.LoadProductOfferingsFailure({ error: err }));
                    }
                })
            )
        )
        )
    );

    loadPennProviders: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
        ofType<fromActions.LoadPennProviders>(
            fromActions.ProtectionProductsActionTypes.LOAD_PENN_PROVIDERS
        ),
        mergeMap((action: fromActions.LoadPennProviders) =>
            this.protectionProductsService.getPennProviders(action.payload.dealerCode).pipe(
                map((pennProviders: IPennProvider[]) => new fromActions.LoadPennProvidersSuccess({ pennProviders })),
                catchError(err => of(new fromActions.LoadPennProvidersFailure({ error: err })))
            )
        )
        )
    );

    LoadPennProductDefaults: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
        ofType<fromActions.LoadPennProductDefaults>(
            fromActions.ProtectionProductsActionTypes.LOAD_PENN_PRODUCT_DEFAULTS
        ),
        mergeMap((action: fromActions.LoadPennProductDefaults) =>
            this.protectionProductsService.getPennProductDefaults(action.payload.dealerCode, action.payload.providerName, action.payload.productId).pipe(
                map((pennProducts: IGetPennProductDefaults) => new fromActions.LoadPennProductDefaultsSuccess({ pennProducts })),
                catchError(err => {
                    if (err.status === 400) {
                        this.pPComponentService.addToDisabledProductList(action.payload.providerName, action.payload.productName);
                    }
                    this.pPComponentService.beginAddNewProduct(action.payload.providerName);
                    this.snackBar.open(err.error.message, 'Dismiss', { duration: 4000 });
                    return of(new fromActions.LoadPennProductDefaultsFailure({ error: err.error.message }));
                })
            )
        )
        )
    );

    loadTaxCategories: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
        ofType<fromActions.LoadTaxCategories>(
            fromActions.ProtectionProductsActionTypes.LOAD_TAX_CATEGORIES
        ),
        mergeMap((action: fromActions.LoadTaxCategories) =>
            this.protectionProductsService.getTaxCategories(action.payload.dealerCode).pipe(
                map((taxCategories: ITaxCategory[]) => new fromActions.LoadTaxCategoriesSuccess({ taxCategories })),
                catchError(err => of(new fromActions.LoadTaxCategoriesFailure({ error: err })))
            )
        )
        )
    );

    loadPacChangelogs: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
        ofType<fromActions.LoadPacChangelogs>(
            fromActions.ProtectionProductsActionTypes.LOAD_PAC_CHANGELOGS
        ),
        mergeMap((action: fromActions.LoadPacChangelogs) =>
            this.protectionProductsService.getPacChangelogs(action.payload.dealerCode, action.payload.productName, action.payload.providerId).pipe(
                map((pacChangelogs: IPacChangelog[]) => new fromActions.LoadPacChangelogsSuccess({ pacChangelogs })),
                catchError(err => {
                    this.snackBar.open('Unable to fetch PAC Changelogs.', 'Dismiss', { duration: 4000 });
                    return of(new fromActions.LoadPacChangelogsFailure({ error: err }))
                })
            )
        )
        )
    );


    updateProtectionProducts: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
        ofType<fromActions.UpdateProtectionProducts>(
            fromActions.ProtectionProductsActionTypes.UPDATE_PROTECTION_PRODUCTS
        ),
        mergeMap((action: fromActions.UpdateProtectionProducts) =>
            this.protectionProductsService.updateProtectionProducts(action.payload.dealerCode, action.payload.protectionProducts).pipe(
                map(
                    (protectionProducts: any) => {
                        this.snackBar.open('Protection Product details updated successfully.', 'Dismiss', { duration: 4000 });
                        return new fromActions.UpdateProtectionProductsSuccess({ protectionProducts });
                    },
                ),
                catchError(err => {
                    this.snackBar.open(err.error.message, 'Dismiss', { duration: 4000 });
                    return of(new fromActions.UpdateProtectionProductsFailure({ error: err.error.message }));
                })
            ))
        )
    );

    updateTfsProtectionProducts: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
        ofType<fromActions.UpdateTfsProtectionProducts>(
            fromActions.ProtectionProductsActionTypes.UPDATE_TFS_PROTECTION_PRODUCTS
        ),
        mergeMap((action: fromActions.UpdateTfsProtectionProducts) =>
            this.protectionProductsService.updateTfsProtectionProducts(action.payload.dealerCode, action.payload.protectionProducts).pipe(
                map(
                    (protectionProducts: ISaveProducts) => {
                        const dealerCode = this.userProfileService.getProfile().dealerCd;
                        this.deployJobManageState.dispatch(
                            new deployJobManagementActions.LoadDeployJobConfig({ dealerCode })
                        );
                        this.snackBar.open('Protection Product details updated successfully.', 'Dismiss', { duration: 4000 });
                        return new fromActions.UpdateTfsProtectionProductsSuccess({ protectionProducts });
                    },
                ),
                catchError(err => {
                    this.snackBar.open(err.error.message, 'Dismiss', { duration: 4000 });
                    return of(new fromActions.UpdateTfsProtectionProductsFailure({ error: err.error.message }));
                })
            ))
        )
    );

    updateTfsProductsOrder: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
        ofType<fromActions.UpdateTfsProductsOrder>(
            fromActions.ProtectionProductsActionTypes.UPDATE_TFS_PRODUCTS_ORDER
        ),
        mergeMap((action: fromActions.UpdateTfsProductsOrder) =>
            this.protectionProductsService.updateTfsProtectionProducts(action.payload.dealerCode, action.payload.protectionProducts).pipe(
                map(
                    (protectionProducts: ISaveProducts) => {
                        const dealerCode = this.userProfileService.getProfile().dealerCd;
                        this.deployJobManageState.dispatch(
                            new deployJobManagementActions.LoadDeployJobManage({ dealerCode })
                        );
                        this.deployJobManageState.dispatch(
                            new deployJobManagementActions.LoadDeployJobConfig({ dealerCode })
                        );
                        this.snackBar.open('Order details updated successfully.', 'Dismiss', { duration: 4000 });
                        return new fromActions.UpdateTfsProductsOrderSuccess({ protectionProducts });
                    },
                ),
                catchError(err => {
                    this.snackBar.open(err.error.message, 'Dismiss', { duration: 4000 });
                    return of(new fromActions.UpdateTfsProductsOrderFailure({ error: err.error.message }));
                })
            ))
        )
    );

    updateMaxVisiblePP: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
        ofType<fromActions.UpdateMaxVisiblePP>(
            fromActions.ProtectionProductsActionTypes.UPDATE_MAX_VISIBLE_PP
        ),
        mergeMap((action: fromActions.UpdateMaxVisiblePP) =>
            this.protectionProductsService.updateTfsProtectionProducts(action.payload.dealerCode, action.payload.protectionProducts).pipe(
                map(
                    (protectionProducts: ISaveProducts) => {
                        this.snackBar.open('Max visible products updated successfully.', 'Dismiss', { duration: 4000 });
                        return new fromActions.UpdateMaxVisiblePPSuccess({ protectionProducts });
                    },
                ),
                catchError(err => {
                    this.snackBar.open(err.error.message, 'Dismiss', { duration: 4000 });
                    return of(new fromActions.UpdateMaxVisiblePPFailure({ error: err.error.message }));
                })
            ))
        )
    );

}

